import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  private readonly apiUrl = environment.apiUrl;

  private readonly authEndPoint = `${this.apiUrl}/auth`;

  private readonly loginEndpoint = 'login';

  private readonly validateTokenEndpoint = 'validate-token';

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) {}

  public login(
    username: string,
    password: string,
  ): Observable<{ accessToken: string }> {
    return this.httpClient.post<{ accessToken: string }>(
      `${this.authEndPoint}/${this.loginEndpoint}`,
      {
        username,
        password,
      },
    );
  }

  public validateToken(token: string) {
    return this.httpClient.post(
      `${this.authEndPoint}/${this.validateTokenEndpoint}`,
      null,
      { params: new HttpParams({ fromObject: { token } }) },
    );
  }

  public isUserAuthenticated() {
    const token = this.cookieService.getCookie('token');

    if (!token) {
      return of(false);
    }

    return this.validateToken(token).pipe(
      map(() => true),
      catchError(() => {
        return of(false);
      }),
    );
  }
}
